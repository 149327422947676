import { ref, reactive } from "vue";
import * as API from "@/API/payment";
import useThousandsAndTwoDecimal from "@/utils/payment/useThousandsAndTwoDecimal";
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { message } from "ant-design-vue";
import downloadFile from '@/utils/payment/downloadFile'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import { createVNode } from 'vue';
import { Modal } from 'ant-design-vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

type Key = ColumnProps['key'];

type SearchParams = {
    payoutRound?: string;
    entity: string;
    dealerCode: string;
    swtCode: string;
    dealerName: string;
    region?: string;
    status?: string;
}

type CommentData = {
    comment: string;
    date: string;
}

export interface SalesBreakdownData {
    payoutRound: string;
    region: string;
    dealerCode: string;
    dealerNameEn: string;
    dealerNameCn: string;
    amountWVat: string;
    amountWOVat: string;
    status: string;
    fileId?: string;
    id: string;
    commentList: CommentData[];
}

type fileData = {
    code: string;
    value: string;
}

const useDealerBreakdownReport = () => {
    const store = useStore()
    const route = useRoute()
    const state = reactive<SearchParams>({
        payoutRound: undefined,
        entity: '',
        dealerCode: '',
        swtCode: '',
        dealerName: '',
        region: undefined,
        status: undefined,
    })
    const searchParams: SearchParams = {
        payoutRound: undefined,
        entity: '',
        dealerCode: '',
        swtCode: '',
        dealerName: '',
        region: undefined,
        status: undefined,
    }
    const total = ref<number>(0)
    const pageNumber = ref<number>(0)
    const pageSize = ref<number>(10)
    const entityList = ref<string[]>([])
    const payoutRoundList = ref<string[]>([])
    const regionList = ref<string[]>([])
    const statusList = ref<{code: string;value: string}[]>([])
    const columnsData = ref([
        { title: "Payout Round", dataIndex: "payoutRound", width: 180},
        // { title: "Region", dataIndex: "region", width: 180},
        { title: "Dealer Code", dataIndex: "dealerCode", width: 180},
        { title: "Dealer Name (EN)", dataIndex: "dealerNameEn", width: 180},
        { title: "Dealer Name (ZH)", dataIndex: "dealerNameCn", width: 180},
        { title: "Payout Amount w/o VAT", dataIndex: "amountWOVat",customRender: useThousandsAndTwoDecimal(), width: 190, align: "right"},
        { title: "Payout Amount w VAT", dataIndex: "amountWVat",customRender: useThousandsAndTwoDecimal(),width: 190, align: "right"},
        { title: "Status", dataIndex: "status", width: 180, align: "center"},
        { title: "Opration", dataIndex: "action",slots: { customRender: 'action' }, key: "action", width: 100},
        { title: "Attachment", dataIndex: "attachment",slots: { customRender: 'attachment' }, key: "attachment", width: 100},
        { title: "Comment", dataIndex: "comment",slots: { customRender: 'comment' }, key: "comment", width: 100, align: 'center'}
    ])
    const tableData = ref<SalesBreakdownData[]>()
    const rowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: SalesBreakdownData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: SalesBreakdownData[]) => {
            rowSelection.selectedRowKeys = selectedRowKeys;
            rowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '30px'
    })
    const getTableData = () => {
        rowSelection.selectedRowKeys = []
        rowSelection.selectedRowData = []
        const params = {
            payoutRound: searchParams.payoutRound,
            entity: searchParams.entity,
            dealerCode: searchParams.dealerCode,
            swtCode: searchParams.swtCode,
            dealerNameCn: searchParams.dealerName,
            dealerNameEn: '',
            region: searchParams.region,
            status: searchParams.status,
            pageNumber: pageNumber.value,
            pageSize: pageSize.value
        }
        API.salesBreakDownList(params).then(data => {
            tableData.value = data.content
            total.value = data.totalElements
        })
    }
    const doSearch = () => {
        searchParams.payoutRound = state.payoutRound ? state.payoutRound : ''
        searchParams.entity = state.entity ? state.entity : ''
        searchParams.dealerCode = state.dealerCode ? state.dealerCode : ''
        searchParams.swtCode = state.swtCode ? state.swtCode : ''
        searchParams.dealerName = state.dealerName ? state.dealerName : ''
        searchParams.region = state.region ? state.region : ''
        searchParams.status = state.status ? state.status : ''
        pageNumber.value = 1
        if (searchParams.entity === 'FBAC') {
            columnsData.value = [
                { title: "Payout Round", dataIndex: "payoutRound", width: 180},
                // { title: "Region", dataIndex: "region", width: 180},
                { title: "Dealer Code", dataIndex: "dealerCode", width: 180},
                { title: "SWT Code", dataIndex: "swtCode", width: 180},
                { title: "Dealer Name (EN)", dataIndex: "dealerNameEn", width: 180},
                { title: "Dealer Name (ZH)", dataIndex: "dealerNameCn", width: 180},
                { title: "Payout Amount w/o VAT", dataIndex: "amountWOVat",customRender: useThousandsAndTwoDecimal(), width: 190, align: "right"},
                { title: "Payout Amount w VAT", dataIndex: "amountWVat",customRender: useThousandsAndTwoDecimal(),width: 190, align: "right"},
                { title: "Status", dataIndex: "status", width: 180, align: "center"},
                { title: "Opration", dataIndex: "action",slots: { customRender: 'action' }, key: "action", width: 100},
                { title: "Attachment", dataIndex: "attachment",slots: { customRender: 'attachment' }, key: "attachment", width: 100},
                { title: "Comment", dataIndex: "comment",slots: { customRender: 'comment' }, key: "comment", width: 100}
            ]
        } else {
            columnsData.value = [
                { title: "Payout Round", dataIndex: "payoutRound", width: 180},
                // { title: "Region", dataIndex: "region", width: 180},
                { title: "Dealer Code", dataIndex: "dealerCode", width: 180},
                { title: "Dealer Name (EN)", dataIndex: "dealerNameEn", width: 180},
                { title: "Dealer Name (ZH)", dataIndex: "dealerNameCn", width: 180},
                { title: "Payout Amount w/o VAT", dataIndex: "amountWOVat",customRender: useThousandsAndTwoDecimal(), width: 190, align: "right"},
                { title: "Payout Amount w VAT", dataIndex: "amountWVat",customRender: useThousandsAndTwoDecimal(),width: 190, align: "right"},
                { title: "Status", dataIndex: "status", width: 180, align: "center"},
                { title: "Operation", dataIndex: "action",slots: { customRender: 'action' }, key: "action", width: 100},
                { title: "Attachment", dataIndex: "attachment",slots: { customRender: 'attachment' }, key: "attachment", width: 100},
                { title: "Comment", dataIndex: "comment",slots: { customRender: 'comment' }, key: "comment", width: 100}
            ]
        }
        getTableData()
    }
    const getInitSelect = () => {
        // entityList.value = ['BBAC', 'FBAC', 'MBCL', 'DENZA']
        // if(entityList.value.length > 0) {
        //     state.entity = entityList.value[0]
        // }
        state.payoutRound = store.state.payment.breakdownParams.payoutRound
        state.entity = store.state.payment.breakdownParams.entity
        API.salesBreakDownInitSelect(state.entity).then(data => {
          // payoutRoundList.value = data.payoutRound
          // regionList.value = data.region
          statusList.value = data.status
          // if (payoutRoundList.value.length > 0) {
          //     state.payoutRound = payoutRoundList.value[0]
          //     doSearch()
          // }
        })
        doSearch()
    }
    const doReset = () => {
        Object.assign(state, {
            payoutRound: undefined,
            entity: '',
            dealerCode: '',
            dealerName: '',
            region: undefined,
            status: undefined,
        })
        entityList.value = []
        tableData.value = []
        getInitSelect()
    }
    const pageChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    };
    const sizeChange = (page: number, size: number) => {
        pageNumber.value = page > 0 ? page : 1
        pageSize.value = size
        getTableData()
    }
    const modalVisible = ref(false)
    const modalTitle = ref('')
    const fileList = ref<fileData[]>([])
    const fileColumns = [
        { title: "File Name", dataIndex: "value"}
    ]
    const fileRowSelection = reactive<{ selectedRowKeys: Key[]; selectedRowData: fileData[]; onChange: Function;columnWidth: string}>({
        onChange: (selectedRowKeys: Key[], selectedRows: fileData[]) => {
            fileRowSelection.selectedRowKeys = selectedRowKeys;
            fileRowSelection.selectedRowData = selectedRows;
        },
        selectedRowKeys: [],
        selectedRowData: [],
        columnWidth: '20px'
    })
    const btnName = ref('')
    const loading = ref(false)
    const dealerCode = ref('')
    const modalClose = () => {
        modalVisible.value = false
        modalTitle.value = ''
        fileList.value = []
        fileRowSelection.selectedRowKeys = []
        fileRowSelection.selectedRowData = []
        btnName.value = ''
    }
    const getFileList = async () => {
        const params = {
            entity: searchParams.entity
        }
        fileList.value = await API.salesBreakDownFileList(params)
    }
    const downloadFunc = (fileCode = '') => {
        const dealerCodeList: string[] = []
        rowSelection.selectedRowData.forEach((item: SalesBreakdownData) => {
            dealerCodeList.push(item.dealerCode)
        })
        const params = {
            url: '/ptapi/export/dealer/breakdown/downloadFile/sales',
            method: 'post',
            params: {
                dealerCode: '',
                dealerCodeList: dealerCode.value ? [ dealerCode.value ] : dealerCodeList,
                entity: searchParams.entity,
                entityList: [],
                fileList: fileCode ? [ fileCode ] : fileRowSelection.selectedRowKeys,
                payoutRound: searchParams.payoutRound
            }
        }
        downloadFile(params).then(() => {
            loading.value = false
        }).catch(msg => {
            loading.value = false
            message.warning(msg)
        })
    }
    const downloadClick = async (dealerCodeParam: string) => {
        if (!dealerCodeParam && rowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        dealerCode.value = dealerCodeParam
        modalTitle.value = 'Download Files'
        btnName.value = 'Download'
        await getFileList()
        if (fileList.value.length > 1) {
            modalVisible.value = true
        } else {
            downloadFunc(fileList.value[0].code)
        }
    }
    const withdrawFunc = (fileCode = '') => {
        Modal.confirm({
            title: 'Are you sure withdraw?',
            icon: createVNode(ExclamationCircleOutlined),
            okText: 'Withdraw',
            okType: 'primary',
            cancelText: 'Cancle',
            onOk() {
                const params = {
                    fileList: fileCode ? [ fileCode ] : fileRowSelection.selectedRowKeys,
                    ids: rowSelection.selectedRowKeys
                }
                API.salesBreakDownBatchWithdraw(params).then(res => {      
                    loading.value = false
                    if (res.code === '0') {
                        message.success('Sucessfully Withdraw!')
                        modalClose()
                        getTableData()
                    } else {
                        message.warning(res.message)
                    }
                }).catch(() => {
                    loading.value = false
                })
            },
            onCancel() {
                loading.value = false
            }
        })
    }
    const releaseClick = async () => {
        if (route.query.type === 'view') return
        if (rowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        await getFileList()
        const fileIdList: string[] = []
        fileList.value.forEach((item: fileData) => {
            fileIdList.push(item.code)
        })
        Modal.confirm({
            title: 'Are you sure release?',
            icon: createVNode(ExclamationCircleOutlined),
            okText: 'Release',
            okType: 'primary',
            cancelText: 'Cancle',
            onOk() {
                const params = {
                    fileList: fileIdList,
                    ids: rowSelection.selectedRowKeys
                }
                API.salesBreakDownBatchRelease(params).then(res => {
                    if (res.code === '0') {
                        message.success('Sucessfully Release!')
                        getTableData()
                    } else {
                        message.warning(res.message)
                    }
                })
            }
        })
    }
    const withdrawClick = async () => {
        if (route.query.type === 'view') return
        if (rowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        modalTitle.value = 'Withdraw Files'
        btnName.value = 'Withdraw'
        await getFileList()
        if (fileList.value.length > 1) {
            modalVisible.value = true
        } else {
            withdrawFunc(fileList.value[0].code)
        }
    }
    const confirmClick = () => {
        if (fileRowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        loading.value = true
        if (btnName.value === 'Download') {
            downloadFunc()
        } else if (btnName.value === 'Withdraw') {
            withdrawFunc()
        }
    }

    // view弹出框
    const viewVisible = ref(false)
    const fileId = ref('')
    const dealerDetailApproveId = ref('')
    const openView = (record: SalesBreakdownData) => {
        if (!record.fileId) return
        fileId.value = record.fileId
        dealerDetailApproveId.value = record.id
        viewVisible.value = true
    }
    
    const commentVisible = ref(false)
    const commentData = ref<CommentData[]>()
    const openComment = (record: SalesBreakdownData) => {
      if (record.commentList.length === 0) return
      commentData.value = record.commentList
      commentVisible.value = true
    }

    const viewBatchDownload = () => {
        if (rowSelection.selectedRowKeys.length === 0) {
            message.warning('Please select the data first')
            return
        }
        const attachmentsDownloadReqVOS = []
        for (let i = 0; i < rowSelection.selectedRowData.length; i ++) {
            if (rowSelection.selectedRowData[i].fileId && rowSelection.selectedRowData[i].dealerCode) {
                attachmentsDownloadReqVOS.push({
                    dealerCode: rowSelection.selectedRowData[i].dealerCode,
                    fileId: rowSelection.selectedRowData[i].fileId
                })
            }
        }
        const params = {
            url: '/ptapi/downloadFiles',
            method: 'post',
            params: {
                attachmentsDownloadReqVOS,
                entity: searchParams.entity,
                payoutRound: searchParams.payoutRound
            }
        }
        downloadFile(params)
    }
    return {
        state,
        entityList,
        payoutRoundList,
        regionList,
        statusList,
        getInitSelect,
        columnsData,
        tableData,
        rowSelection,
        doSearch,
        doReset,
        pageChange,
        sizeChange,
        pageSize,
        pageNumber,
        total,
        downloadClick,
        releaseClick,
        withdrawClick,
        viewBatchDownload,
        modalVisible,
        modalClose,
        modalTitle,
        fileColumns,
        fileList,
        fileRowSelection,
        confirmClick,
        btnName,
        loading,
        viewVisible,
        openView,
        fileId,
        dealerDetailApproveId,
        getTableData,
        commentVisible,
        commentData,
        openComment
    }
}
export default useDealerBreakdownReport